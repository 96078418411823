import React from 'react'
import Layout from '../../components/layout/layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarSeOne from "../../components/layout/sidemenu/sidemenu_se_one"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import ContentHeader from '../../components/page-sections/content-header'
import FocusQuestion from '../../components/page-sections/focus-question'
import Introduction from '../../components/page-sections/introduction'
import ButtonTop from '../../components/buttons/button-top'
import ButtonNext from '../../components/buttons/button-next'

import rowImage from '../../queries/images/row-image'

import unit1Icon from '../../images/icons/unit_01_icon.svg'
import experimentBottle06 from '../../images/student-pages/unit-1/06_bottle_experiments.gif'
import pHscale07 from '../../images/student-pages/unit-1/07_ph_scale.jpg'
import CO2ColorPlot08 from '../../images/student-pages/unit-1/08_co2_color_plot.gif'


const CarbonFizz11 = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - 1.1 Carbon Fizz" />
    <Container className="mb-5" style={{marginRight: 0, marginLeft: 0 }} fluid>
      <Row>
        <Col md="auto">
          <SideBarSeOne location={props.location.pathname} />
        </Col>
        <Col>
          <CCBreadcrumb
            pathname={props.location.pathname}
            title={'1.1 Carbon Fizz'}
            replace={props.replace}
          />
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
          <Card>
            <Card.Body>
              <ContentHeader
                icon={ unit1Icon }
                iconAlt="Unit 1 icon"
                studentPageHeader="Unit 1: Carbon &amp; Climate in the Past"
                unitNumber="1.1"
                studentPageHeaderSub="Carbon Fizz"
                sectionA={ <>Carbon In</> }
                sectionALink="/unit-1/1.1-carbon-fizz/#carbon-in"
                sectionB={ <>Carbon Out</> }
                sectionBLink="/unit-1/1.1-carbon-fizz/#carbon-out"
                sectionC={ <>Reflect and Connect</> }
                sectionCLink="/unit-1/1.1-carbon-fizz/#reflect-and-connect"
              />

              <FocusQuestion
                focusQuestionBodyText={ <>How can I monitor and model the movement of carbon in a system?</> }
              />

              <Introduction
                paragraphOne={
                  <>
                    <Row className="d-md-none">
                      <Col>
                        <Img
                          className="rounded img-fluid mb-3"
                          fluid={props.data.image3.childImageSharp.fluid}
                          alt="Trees"
                        />
                      </Col>
                    </Row>
                    <Img
                      className="smallImageLeft rounded mr-4 img-fluid d-none d-md-block"
                      fluid={props.data.image3.childImageSharp.fluid}
                      alt="Trees"
                    />

                    <p>Carbon: It is vital for life. In your body, carbon makes up about 50 percent of your tissues and about 28 percent of your bones. In plants, carbon forms roughly half of tissues.</p>
                  </>
                }

                paragraphTwo={
                  <>
                    <p>Carbon is also a natural part of the air that you breathe. Maybe you have learned about photosynthesis in science. Photosynthesis transfers carbon from the air to plants or other photosynthesizing organisms that are growing. As you can see, carbon connects to life in many ways.</p>
                  </>
                }

                paragraphThree={
                  <>
                    <p>Carbon is also a key part of non-living systems (abiotic), such as minerals like diamond or graphite. It is found in rocks, too, including limestone and rocks with fossil fuels, such as petroleum, coal, and natural gas. These are forms of carbon that you use and rely on.</p>
                  </>
                }

                paragraphFour={
                  <>
                    <p>Think about this: How might you describe carbon to a friend who was not in your science class? Would you describe a color for carbon? How many forms of carbon can you think of? How does carbon relate to your energy needs? <em>Carbon Connections</em> will help you answer questions like these. You will start thinking about carbon and how it moves around Earth.</p>
                  </>
                }

                paragraphFive={
                  <>
                    <Row className="d-md-none">
                      <Col>
                        <Img
                          className="rounded img-fluid mb-3"
                          fluid={props.data.image4.childImageSharp.fluid}
                          alt="Fossil"
                        />
                      </Col>
                    </Row>
                    <Img
                      className="smallImageRight rounded ml-4 mb-4 img-fluid d-none d-md-block"
                      fluid={props.data.image4.childImageSharp.fluid}
                      alt="Fossil"
                    />

                    <p>In this lesson, you will learn that:</p>
                    <ul>
                      <li>Carbon dioxide (CO<sub>2</sub>) is an important molecule that contains carbon.</li>
                      <li>You can use models to demonstrate the transfer of carbon in the Earth system.</li>
                      <li>You can use chemical indicators and color to monitor changes in solutions.</li>
                    </ul>
                  </>
                }

                paragraphSix={
                  <>
                    <p>For Lesson 1.1, consider the focus question. It will help to guide your thinking about how carbon moves in the Earth system. This question, along with two experiments, will help you model carbon in the Earth system.</p>
                  </>
                }
              />

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="carbon-in" className="cardHeader">
                      <h2>
                        A. <span className="headerFontBlue">Carbon In</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        Start with this investigation to simulate the movement of carbon in a system. Have you heard of dry ice? It's a solid form of carbon dioxide (CO<sub>2</sub>), just as ice is the solid form of water (H<sub>2</sub>O). CO<sub>2</sub> must be much colder than ice to be a solid, however. Also, CO<sub>2</sub> does not melt to a liquid and become "wet" like water. It changes from solid CO<sub>2</sub> directly to CO<sub>2</sub> gas. That is why it's "dry."
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image5.childImageSharp.fluid}
                            alt="Trees"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight rounded ml-4 img-fluid d-none d-md-block"
                        fluid={props.data.image5.childImageSharp.fluid}
                        alt="Trees"
                      />

                      <p>
                        Dry ice is a good source of CO<sub>2</sub> for science class. Your goal is to monitor and observe how CO<sub>2</sub> gas interacts with water. How will you be able to tell if the CO<sub>2</sub> is interacting with the water? To do this, you will use a chemical indicator that changes the color of water as carbon moves in the system. Do the following steps to investigate this process.
                      </p>

                      <ol>
                        <li>You have likely used many indicators. Do these steps to learn about chemical indicators.
                          <ol type="a">
                            <li>
                              Read the following:
                              An <strong>indicator</strong> shows you the value of something, and how a system may be changing. For example, temperature is an <em>indicator</em> of thermal energy in matter. Or, the balance in dollars for your bank account is an <em>indicator</em> of how much money you have saved. In this activity, you'll use a chemical indicator called <strong>bromthymol blue</strong>, or <strong>BTB</strong>. When water has BTB in it, the color changes when carbon moves in or out of the water.
                            </li>
                            <li>What other <em>indicators</em> can you think of? Maybe they are in sports or business (for example, look up these indicators: "earned run average", or ERA; "NASDAQ" or "S&amp;P500").</li>
                          </ol>
                        </li>
                        <li>Investigate the setup described in this step. Your class will start with a container of water. BTB has been added, giving a light blue color to the water. Some dry ice (CO<sub>2</sub>) is suspended over the water.
                          <ol type="a">
                            <li>Draw or take a snapshot of the system. Record the color before the dry ice enters the water.</li>
                            <li>After some time, do you see color changes occurring? What are they? What does the color change tell you about CO<sub>2</sub> from the dry ice?</li>
                            <li>Sketch the container or take a photo to show the color changes. What patterns develop to show that carbon is moving in the system?</li>
                          </ol>
                        </li>
                        <li>
                          You can make a claim about whether or not the CO<sub>2</sub> and water are interacting. Part of making a claim is supporting the claim with evidence. Use the following sentence starters to add details to a claim and evidence: <br />
                          <em>Carbon from the CO<sub>2</sub> of the dry ice is entering &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;.</em> <br />
                          <em>The evidence I saw for this transfer is &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;.</em>
                        </li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="carbon-in-video" className="cardHeader">
                      <h2>
                        <span className="headerFontBlue">Carbon In</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <div className="embed-container mb-3">
                        <iframe title="carbon in" className="videoSetting" src="https://player.vimeo.com/video/307385313" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                      </div>

                      {/* <Video vimeo_id="307385313" /> */}

                      <p>
                        You may wonder why CO<sub>2</sub> makes water change color. Watch the Carbon In time lapse video of this reaction. The reaction takes approximately one hour in real time. This relates to some very important reactions. These reactions are a key part of all marine and aquatic systems. You can learn some more details about the reaction by clicking on Explore More: Carbon Indicators&mdash;Responding to Reactions.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="explore-more-carbon-indicators" className="cardHeader">
                      <h2>
                        <span className="headerFontBlue">Explore More: Carbon Indicators&mdash;Responding to Reactions</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p className="lead">
                        Carbon Indicators&mdash;Responding to Reactions
                      </p>

                      <p>
                        You may think that science is about measuring values, or getting the "answer." While that can be a part of science, indicators&mdash;in science or any other field &mdash; can tell you important things about systems. Besides giving you values, indicators tell you whether something has changed, and whether it's changed by a lot or just a little.
                      </p>

                      <p>
                        You used bromthymol blue (BTB) as an indicator in Lesson 1.1. In your investigation, the color of the solution with BTB changed as carbon moved, but the molecule with the carbon also changed. The reactions showing those changes are in the equations below. These reactions are a fundamental part of carbon cycling in all freshwater and marine environments.
                      </p>

                      <p>
                        The two equations below show CO<sub>2</sub> moving in and out of water. The subscript "aq" refers to aqueous for the substance that is dissolved in water. The subscript "gas" refers to the substance in a gas state. This CO<sub>2</sub> is also an important part of air. You should be able to tell which reaction represents <em>Carbon In</em> for the air, and which one represents <em>Carbon Out</em> of the air. If you are not positive that you know, check with a partner.
                      </p>

                      <p className="text-center">
                        CO<sub>2(gas)</sub> &rarr; CO<sub>2(aq)</sub>

                        CO<sub>2(aq)</sub> &rarr; CO<sub>2(gas)</sub>
                      </p>

                      <p>
                        Once a CO<sub>2</sub> molecule has entered the water, it can react with the water, H<sub>2</sub>O. A CO<sub>2</sub> molecule can join with a water to make <strong>carbonic acid</strong>, H<sub>2</sub>CO<sub>3</sub>.
                      </p>

                      <p className="text-center">
                        H<sub>2</sub>CO<sub>3</sub> + H<sub>2</sub>O<sub>(l)</sub> &rarr; H<sub>2</sub>CO<sub>3(aq)</sub>
                      </p>

                      <p>
                        It is called an acid because of the next change. Carbonic acid in water separates (dissociates) to the ions H<sup>+</sup> and (HCO3)<sup>-1</sup>, the <strong>bicarbonate ion</strong>. An excess of hydrogen ions (H<sup>+</sup>) leads to a solution that is <strong>acidic</strong>. The reaction is:
                      </p>

                      <p className="text-center">
                        H<sub>2</sub>CO<sub>3(aq)</sub> &rarr; (HCO<sub>3</sub>)<sup>-1</sup><sub>(aq)</sub> + H<sup>+1</sup><sub>(aq)</sub>
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded imageBorder img-fluid mb-3"
                            src={ experimentBottle06 }
                            alt="Experiment bottle"
                          />
                        </Col>
                      </Row>
                      <img
                        className="smallImageRight imageBorder rounded ml-4 mb-2 img-fluid d-none d-md-block"
                        src={ experimentBottle06 }
                        alt="Experiment bottle"
                      />

                      <p>
                        By combining the equations, you get a full reaction sequence that connects CO<sub>2</sub> to H<sup>+</sup>. The full reaction is:
                      </p>

                      <p className="text-center">
                        CO<sub>2(aq)</sub> + H<sub>2</sub>O<sub>(l)</sub> &harr; H<sub>2</sub>CO<sub>3(aq)</sub> &harr; (HCO<sub>3</sub>)<sup>-1</sup><sub>(aq)</sub> + H<sup>+1</sup><sub>(aq)</sub>
                      </p>

                      <p>
                        What happens when you increase CO<sub>2</sub> in this system? Adding more CO<sub>2</sub> to water forces the reaction to the products in the image. This creates more H<sup>+</sup>, and the system becomes more acidic. The image shows color changes for BTB that go with that change in acidity. It also shows that H<sup>+</sup> and color also relate to a numerical scale. This is the pH scale for the amount of H<sup>+</sup> in solution. A change from blue to yellow for BTB indicates a change to lower values of pH.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded imageBorder img-fluid mb-3"
                            src={ pHscale07 }
                            alt="pH scale"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter imageBorder rounded img-fluid d-none d-md-block mb-3"
                        src={ pHscale07 }
                        alt="pH scale"
                      />

                      <p>
                        What if you decreased CO<sub>2</sub>? The color change to green then blue indicated pH at 7, and then going slightly higher than 7. In this case, CO<sub>2</sub> leaving the water is the reaction going to the left, with a decrease in the H<sup>+</sup>. The system then becomes more <strong>basic</strong>.
                      </p>

                      <p className="text-center">
                        CO<sub>2(aq)</sub> + H<sub>2</sub>O<sub>(l)</sub> &larr; H<sub>2</sub>CO<sub>3(aq)</sub> &larr; (HCO<sub>3</sub>)<sup>-1</sup><sub>(aq)</sub> + H<sup>+1</sup><sub>(aq)</sub>
                      </p>

                      <p>
                        Bromthymol blue is very useful because it displays a rapid color change, right in the middle of the scale (pH=7).
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="carbon-out" className="cardHeader">
                      <h2>
                        B. <span className="headerFontBlue">Carbon Out</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        In this section, you will investigate another model of carbon moving in the Earth system. Keep the model in mind as you learn more about the carbon cycle and climate in Unit 1. You will use bromthymol blue (BTB) again.
                      </p>

                      <p className="lead">Carbon Out Activity</p>
                      <p className="lead"><em>Materials per Team</em></p>
                      <ul>
                        <li>1 small, clear container that can be closed tightly</li>
                        <li>Club soda water with bromthymol blue (BTB) indicator</li>
                        <li>Clean sheet of white paper</li>
                      </ul>

                      <p className="lead"><em>Process and Procedure</em></p>
                      <Row className="d-md-none">
                        <Col>
                          <img
                          className="rounded img-fluid mb-3 imageBorder"
                          src={ experimentBottle06 }
                          alt="Experiment bottle"
                        />
                        </Col>
                      </Row>
                      <img
                        className="smallImageRight imageBorder rounded ml-4 img-fluid d-none d-md-block"
                        src={ experimentBottle06 }
                        alt="Experiment bottle"
                      />

                      <p>Leave the control tightly closed. You will shake the experimental bottle only.</p>
                      <ol start="4">
                        <li>Before you start this investigation, look at the bottle and CO<sub>2</sub> from step 1.1a, Carbon In. What changes have occurred?</li>
                        <li>
                          Listen to your teacher's instructions for how your class will set up the investigation. Then complete these steps.
                          <ol type="a">
                            <li>Your teacher will have a bottle of soda water. He or she, or a volunteer, will open it carefully, quickly add 25-30 drops of BTB, and then close it tightly.</li>
                            <li>Your teacher will gently swirl the mixture to mix the BTB in the water.</li>
                            <li>Draw or take a snapshot of the system. This is the <strong>control bottle</strong>.</li>
                            <li>You will then get an empty plastic bottle, which your teacher will pour the solution into (the mixture of soda water and BTB, about 1 cm deep). You will have the <strong>experimental bottle</strong>.</li>
                          </ol>
                        </li>
                        <li>Shake your experimental bottle as hard as you can for 15 seconds. Open the cap to release the "fizz."</li>
                        <li>
                          Repeat step 6 at least five or six times. The more you shake, the better! As you do, record the changes that are occurring in your bottle.
                          <ol type="a">
                            <li>What has happened to the color of the solution? Compare your bottle to the control.</li>
                            <li>What is happening to the amount of fizz?</li>
                            <li>Your teacher will have another bottle that has been open for a few days and has no fizz left. Record its color.</li>
                          </ol>
                        </li>
                        <li>
                          Make a graph like the one below to show your findings. Include data from at least three bottles in step 7. Add any other labels to the graph that will help you show your results. <br />
                          <em>You can also include the results from other groups in your graph. Depending on the amount of water, and the amount of shaking, their results may be a little different.</em>
                        </li>
                      </ol>

                      <Row className="d-md-none">
                        <Col>
                          <img
                          className="largeImageCenter imageBorder rounded mr-4 img-fluid d-none d-md-block"
                          src={ CO2ColorPlot08 }
                          alt="Carbon dioxide color plot"
                        />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter imageBorder rounded img-fluid d-none d-md-block"
                        src={ CO2ColorPlot08 }
                        alt="Carbon dioxide color plot"
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="reflect-and-connect" className="cardHeader">
                      <h2>
                        C. <span className="headerFontBlue">Reflect and Connect</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <ol start="9">
                        <li>
                          Look again at the bottle with dry ice from <em>Carbon In</em>.
                          <ol type="a">
                            <li>Has the system changed? If so, how has it changed?</li>
                            <li>Use what you have learned to explain the reason for the change.</li>
                          </ol>
                        </li>
                        <li>
                          Think about the investigation you just did. Work with a partner to discuss and write answers to these questions:
                          <ol type="a">
                            <li>What do you know about carbonated water?</li>
                            <li>What was happening as you released the fizz?</li>
                            <li>Think of the bottle of water as a model for part of the Earth system. What systems or setting on Earth might this model?</li>
                            <li>
                              How do you think that this investigation involved carbon? What was the carbon doing? Explain your thinking. <br /> <em>Remember that you aren't expected to know all of the answers at this point; however, it's important to record your ideas and explain your thinking process.</em>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <ButtonNext
                ButtonText="1.2 Carbon from the Past"
                NextLink="/unit-1/1.2-carbon-from-the-past/"
              />

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default CarbonFizz11

export const query = graphql`
  query {
    image3: file(relativePath: { eq: "student-pages/unit-1/03_trees.jpg" }) {
      ...rowImage
    }
    image4: file(relativePath: { eq: "student-pages/unit-1/04_fossil.jpg" }) {
      ...rowImage
    }
    image5: file(relativePath: { eq: "student-pages/unit-1/05_dry_ice.jpg" }) {
      ...rowImage
    }
  }
`
